<template>
  <div class="vg_wrapper">
    <el-card>
      <el-row>
        <el-col :md="24">
          <el-table class="vg_pointer" ref="multiTable" :data="tableData" @row-dblclick="dbClickJp" border>
            <el-table-column label="数据名称" prop="perm_name"/>
          </el-table>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import {get} from "@api/request";
import {optnAPI} from "@api/modules/optn";
export default {
  name: "OptnList",
  data() {
    return {
      tableData: [],
      multiSelection: [],
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData(){
      this.getOptns();
    },
    // 获取信息
    getOptns() {
      get(optnAPI.getAllOptns,
          {
            page_no: this.currentPage,
          })
          .then(res => {
            if(res.data.code === 0){
              this.tableData = res.data.data;
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
            }
          })
          .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          })
    },
    // 分页查询
    changePageSearch(val){
      this.currentPage = val
      this.getOptns()
    },
    // 双击row跳转到详情编辑
    dbClickJp(row){
      this.jump('/optn_edit',{perm_id:row.perm_id})
    }
  }
}
</script>

<style scoped>

</style>